<template>
  <div class="custom-money-input-container">
    <label v-if="label">{{ label }}</label>
    <money
      v-bind="$attrs"
      v-model="inputValue" 
      @input="$emit('input', inputValue)"
    />
    <span v-if="!isValid && errorMessage">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import { Money } from 'v-money';

export default {
  name: 'BaseMoneyInput',
  components: {
    Money
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: null,
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit('input', newValue);
    }
  },
  data() {
    return {
      inputValue: this.value
    };
  }
};
</script>
<style lang="scss">
.custom-money-input-container label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  color: var(--Default-gray-900, #111827);
  margin-bottom: 6px !important;
}

.custom-money-input-container input {
  border: 1px solid var(--Default-gray-300, #d1d5db);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  height: 38px !important;
  padding: 0px 12px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;

  &:disabled {
    background: var(--Default-gray-50, #f9fafb) !important;
    color: #6b7280 !important;
    cursor: not-allowed;
  }
}
</style>
