<template>
  <div class="multiselect-container">
    <label v-if="inputLabel">{{ inputLabel }}</label>
    <Multiselect
      v-bind="$attrs"
      class="custom-multiselect"
      v-on="$listeners"
      :value="localValue"
      @input="updateValue"
    >
      <!-- Slots dinâmicos -->
      <template v-for="(_, slot) in $slots" #[slot]>
        <slot :name="slot"></slot>
      </template>

      <template #caret="{ toggle }">
        <slot name="caret" :toggle="toggle">
          <img
            class="custom-arrow"
            src="@/assets/img/icons/chevron-down.svg"
            alt="Seta"
            width="20"
            height="20"
            @click="toggle"
          />
        </slot>
    </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  inheritAttrs: false,
  components: { Multiselect },
  props: {
    value: [String, Array, Object],
    inputLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value.length ? value : []);
      },
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style>
.multiselect-container {
  position: relative;
}


.multiselect-container label {
  margin-bottom: 6px !important;
  line-height: 20px;
  color: var(--Default-gray-900, #111827);
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
}

.multiselect-container .custom-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 9px;
}

.multiselect-container .multiselect__tags {
  padding: 0px !important;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0px 12px !important;
  min-height: 38px !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.multiselect-container .multiselect__tags .multiselect__tag {
  margin: 5px 5px;
}

.multiselect-container .multiselect__single {
  margin: 0 !important;
}

.multiselect-container .multiselect__select {
  top: 2px !important;
}

.multiselect-container .multiselect__input{
  margin: 0 !important;
  transition: none !important;
}

.multiselect-container .multiselect__option--highlight {
  background-color: var(--Default-color-primary-800) !important;
}

.multiselect-container .multiselect__option--highlight span{
  color: #fff !important;
  background-color: var(--Default-color-primary-800) !important;
}

.multiselect-container .multiselect__content {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.multiselect-container .multiselect__placeholder {
  padding: 0 !important;
  line-height: 100% !important;
}
</style>
