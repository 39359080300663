<template>
  <div class="floating-csat" v-if="isVisible">
    <div class="csat-button" @click="openCsatForm">
      <span>{{ $t('modals.floating_csat.title') }}</span>
    </div>

    <!-- Modal do CSAT -->
    <b-modal id="modal-csat" v-model="showModal" :size="isMobile ? 'sm' : 'lg'" hide-footer hide-header
      :title="`${$t('modals.floating_csat')}`">
      <div class="fast-modal">
        <button class="close-button" @click="showModal = false">×</button>
        <div v-if="fastframe?.url && fastframe?.apiKey" class="fast-modal-container">
          <iframe 
            id="fastframeCSAT" 
            width="800" 
            height="800" 
            style="height: 100%; width: 100%; transform: scale(1); border: none;"
            :src="getIframeUrl()"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/mixins/utils';

export default {
  name: 'FloatingCsat',
  mixins: [utils],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      isVisible: false,
      fastframe: {
        url: process.env.CSAT_FASTQUEST_URL,
        apiKey: process.env.CSAT_FASTQUEST_API_KEY,
        route: this.$route.name
      }
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    userId() {
      return this.userID;
    },
    route(){
      return this.$route.name;
    }
  },
  methods: {
    openCsatForm() {
      this.showModal = true;
    },
    getIframeUrl() {
      return `${this.fastframe.url}?codjob=${this.fastframe.apiKey}&codclientecawi=${this.userId}&location=${this.$route.name}`;
    }
  },
  mounted() {
    this.isVisible = this.isAuthenticated;
  },
  watch: {
    isAuthenticated(newVal) {
      this.isVisible = newVal;
    }
  },
}
</script>

<style lang="scss" scoped>
.floating-csat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;

  .csat-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #002363;
    color: white;
    padding: 12px 20px;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    }

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      color: white;
    }
  }
}

.close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: white;
  border: none;
  color: #333;
  font-size: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  transition: all 0.2s ease;
  border-radius: 4px;

  &:hover {
    transform: rotate(90deg);
    color: #000;
  }

  &:focus {
    outline: none;
  }
}

.fast-modal {
  width: 100%;
}

.fast-modal-container {
  padding: 0px;
  height: 800px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 500px;
  }
}

.fast-modal-iframe {
  display: flex;
  justify-content: center;
}

:deep(.modal-dialog) {
  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }
}
</style>