<template>
    <button :class="['button', ...cssClasses]" :disabled="disabled" v-bind="$attrs" @click="$emit('click', $event)">
      <span v-if="icon && position === 'left'" class="button-icon">
        <img :src="require(`@/assets/img/icons/${icon}.svg`)" />
      </span>
      <slot> </slot>
      <span v-if="icon && position === 'right'" class="button-icon">
        <img :src="require(`@/assets/img/icons/${icon}.svg`)" />
      </span>
    </button>
  </template>
  <script>
  export default {
    name: 'BaseNewButton',
    inheritAttrs: false,
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      outline: {
        type: Boolean,
        default: false,
      },
      variant: {
        type: String,
        required: true,
        validator: (val) =>
          [
            "primary",
            "white",
            "danger",
            "info",
            "info2",
            "info3",
            "link-info",
            "outline-info",
            "outline-info2",
            "outline-primary",
            "link",
            "outline-danger",
            "black",
            "accent_outline",
            "secondary",
            "secondary_outline",
            "active",
            "inactive",
          ].includes(val),
      },
      icon: {
        type: String,
      },
      iconPosition: {
        type: String
      }
    },
    computed: {
      cssClasses() {
        let classes = [];
        this.outline
          ? classes.push(`button-outline-${this.variant}`)
          : classes.push(`button-${this.variant}`);
  
        return classes;
      },
      position() {
        if (!this.iconPosition || this.iconPosition === 'right') {
          return 'right';
        }
        return 'left';
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .button {
    display: flex;
    gap: 12px;
    align-items: center;
    border: none;
    background: #002363;
    color: #fff;
    outline: none !important;
  
    font-size: var(--Default-font-size-base);
    font-weight: var(--Default-font-weight-semibold);
    line-height: var(--Default-line-height-base);
    border-radius: 4px;
    padding: var(--Default-button-padding) !important;
    height: 40px !important;
  
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  
    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
      border: none;
    }
  
    &:focus {
      box-shadow: none;
    }
  }
  
  .button-primary {
    background: var(--Default-color-primary-800);
    border-color: var(--Default-color-primary-800);
  
    &:disabled {
      border-radius: 4px;
      background: #CEDDF3;
      color: var(--Default-white, #FFF);
      cursor: not-allowed !important;
    }
  }
  
  .button-primary-inverted {
    background: #e0fcf4;
    color: $base-color;
  }
  
  .button-white {
    width: auto;
    color: #777;
    background: transparent;
    border: 1px #fff solid;
    cursor: pointer;
  
    &:hover {
      background: #777;
      color: #fff;
    }
  }
  
  .button-danger {
    color: #fff;
    background: #ff0c37;
  
    &:hover {
      box-shadow: 0 8px 20px rgba(255, 12, 55, 0.2);
    }
  }
  
  .button-accent_outline {
    color: #b71a2e;
    background: #ffffff;
    border: 1px solid #ff9e85;
  }
  
  .button-warning {
    color: #000;
    background: #ffcb37;
  
    &:hover {
      box-shadow: 0 8px 20px rgba(252, 226, 156, 0.2);
    }
  }
  
  .button-info {
    width: auto;
    color: #fff;
    background: #002363;
    border: 1px #002363 solid;
    cursor: pointer;
  
    &:hover {
      background: transparent;
      color: #2a63ab;
    }
  }
  
  //Secondary outline
  .button-info2 {
    width: auto;
    color: #002e74;
    background: #ffffff;
    border: 1px solid #5592d5;
    cursor: pointer;
  }
  
  .button-info3 {
    width: auto;
    color: $gray_800;
    background: #fff;
    border: 1px solid $gray_300;
    cursor: pointer;
  }
  
  .button-active {
    width: auto;
    border: 1px solid #2a63ab;
    background: #2a63ab;
    color: #fff;
  }
  
  .button-inactive {
    width: auto;
    border: 1px solid #bdbdbd;
    background: #ffffff;
    color: #091e3e;
  }
  
  .button-black {
    width: auto;
    color: #fff;
    background: #020016;
    cursor: pointer;
  }
  
  .button-link-info {
    color: #fff;
    background: #2a63ab;
    border: none;
    cursor: pointer;
  }
  
  .button-link {
    color: #fff;
    background: #333;
    border: none;
    cursor: pointer;
  }
  
  // Outlines
  
  .button-outline-primary {
    background: transparent;
    color: $base-color;
    border: 1px $base-color solid;
  
    &:hover {
      background: $base-color;
      color: #fff;
    }
  }
  
  .button-outline-white {
    background: transparent;
    color: #777;
    border: 1px #777 solid;
  
    &:hover {
      background: #777;
      color: #333;
    }
  }
  
  .button-outline-danger {
    color: rgb(183, 26, 46);
    background: rgb(255, 255, 255);
    border: 1px solid rgb(255, 158, 133);
  }
  
  .button-outline-info {
    background: transparent;
    color: #2a63ab;
    border: 1px #002363 solid;
  
    &:hover {
      background: #002363;
      color: #fff;
    }
  }
  
  .button-outline-info2 {
    color: #fff;
    background: #002e74;
    border: 1px solid #002e74;
  }
  
  .button-outline-warning {
    background: transparent;
    color: #ffcb37;
    border: 1px #ffcb37 solid;
  
    &:hover {
      background: #ffcb37;
      color: #fff;
    }
  }
  
  .button-outline-black {
    background: transparent;
    color: #000;
    border: 1px #000 solid;
  
    &:hover {
      background: #000;
      color: #fff;
    }
  }
  
  .button-secondary {
    background: transparent;
    color: var(--Default-color-gray-900);
    border: 1px solid var(--Default-color-gray-300);
    cursor: pointer;
  
  }
  
  .button-secondary_outline {
    width: auto;
    color: #002e74;
    background: #ffffff;
    border: 1px solid #5592d5;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .button {
      height: 45px;
      padding: 0 20px !important;
      border-radius: 5px;
      font-size: 13px;
    }
  }
  </style>
  